

























import { Vue, Component, Watch } from 'vue-property-decorator'
import { State, Getter } from 'vuex-class'
import { RootState } from '~/store'
import { IMobileMenu, IMobileMenuLink } from '~/interfaces/menus/mobile-menu'
import MobileLinks from '~/components/mobile/mobile-links.vue'
import dataMobileMenu from '~/data/mobileMenu'
import dataLanguages from '~/data/languages'
import Cross20Svg from '~/svg/cross-20.svg'

@Component({
    components: { MobileLinks, Cross20Svg }
})
export default class MobileMenu extends Vue {
    @State((state: RootState) => state.mobileMenu.isOpen) isOpen!: boolean
    @Getter('auth/loggedIn') isLoggedIn!: boolean;

    bodyWidth: number = 0

    links: IMobileMenu = []

    @Watch('isOpen')
    onIsOpenChange (newValue: boolean) {
        if (newValue) {
            this.open()
            if (this.isLoggedIn) {
                this.links = [...dataMobileMenu,
                { title: 'My Requests', type: 'link', url: '/account/my-requests' },
                { title: 'My Offers', type: 'link', url: '/account/my-offers' },
                { title: 'My Purchases', type: 'link', url: '/account/my-purchases' },
                { title: 'My Shop', type: 'link', url: '/account/my-shop' },
                ]
                this.links = [...dataMobileMenu]

            }
            else{
                this.links = [...dataMobileMenu]
            }
        } else {
            this.close()
        }
    }

    open () {
        this.hideScrollbar()
    }

    close () {
        this.showScrollbar()
    }

    hideScrollbar () {
        this.bodyWidth = document.body.clientWidth

        document.body.style.overflow = 'hidden'
        document.body.style.paddingRight = `${document.body.clientWidth - this.bodyWidth}px`
    }

    showScrollbar () {
        document.body.style.overflow = ''
        document.body.style.paddingRight = ''
    }

    onItemClick (item: IMobileMenuLink) {
        this.$store.commit('mobileMenu/close')

        if (item.data && item.data.type === 'currency') {
            this.$store.commit('currency/set', item.data.currency)
        }
        if (item.data && item.data.type === 'language') {
            const fullPath = this.$route.fullPath
            const re = new RegExp('^/(' + dataLanguages.map(x => x.locale).join('|') + ')(/|$)')
            const path = fullPath.replace(re, '/')

            this.$router.push(`/${item.data.language.locale}${path}`)
        }
    }
}

