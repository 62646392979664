var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        'indicator',
        ("indicator--trigger--" + _vm.trigger),
        {
            'indicator--mobile': _vm.location === 'mobile-header',
            'indicator--hover': _vm.isHovered,
            'indicator--display': _vm.isDisplay,
            'indicator--open': _vm.isOpen,
        }
    ],on:{"mouseenter":_vm.onMouseEnter,"mouseleave":_vm.onMouseLeave}},[(_vm.to && _vm.trigger !== 'click')?_c('AppLink',{staticClass:"indicator__button",attrs:{"to":_vm.to},nativeOn:{"click":function($event){return _vm.onClick.apply(null, arguments)}}},[_c('span',{staticClass:"indicator__area"},[_vm._t("icon"),_vm._v(" "),(_vm.value !== null)?_c('span',{staticClass:"indicator__value"},[_vm._v(_vm._s(_vm.value))]):_vm._e()],2)]):_vm._e(),_vm._v(" "),(_vm.to && _vm.trigger === 'click')?_c('a',{staticClass:"indicator__button",attrs:{"href":_vm.$url.anyLink(_vm.to)},on:{"click":_vm.onClick}},[_c('span',{staticClass:"indicator__area"},[_vm._t("icon"),_vm._v(" "),(_vm.value !== null)?_c('span',{staticClass:"indicator__value"},[_vm._v(_vm._s(_vm.value))]):_vm._e()],2)]):_vm._e(),_vm._v(" "),(!_vm.to)?_c('button',{staticClass:"indicator__button",attrs:{"type":"button"},on:{"click":_vm.onClick}},[_c('span',{staticClass:"indicator__area"},[_vm._t("icon"),_vm._v(" "),(_vm.value !== null)?_c('span',{staticClass:"indicator__value"},[_vm._v(_vm._s(_vm.value))]):_vm._e()],2)]):_vm._e(),_vm._v(" "),(_vm.$slots.default)?_c('div',{ref:"dropdown",staticClass:"indicator__dropdown"},[_vm._t("default")],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }