export const MAIN_CONSTANTS = {
    BASE_URL : 'https://www.find-myparts.com',
    SERVER_URL : 'https://www.find-myparts.com:3001',
    //BASE_URL : 'http://localhost:3000',
    //SERVER_URL : 'http://localhost:3001',
    TINY_MCE_API_KEY: 'ob9z6hj4uekxoyohzmn0aub320jrs3sjbdxv0p7kpew20d6p',
    ROLES: {
        REQUESTER: 'ROLE_REQUESTER',
        SUPPLIER: 'ROLE_SUPPLIER',
        SELLER: 'ROLE_SELLER',
        MODERATOR: 'ROLE_MODERATOR',
        ADMIN: 'ROLE_ADMIN',
        SUPERADMIN: 'ROLE_SUPERADMIN'
    },
    PLAN_TYPES: {
        SUPPLIER: {
            1: 1,
            6: 2,
            12: 3,
        },
        SELLER: {
            1: 4,
            3: 5,
            6: 6,
        },
    },
    NOTIFICATION_TYPE: {
        ALL: 0,
        REQUEST: 1,
        OFFER: 2,
        SHOP: 3,
        PURCHASE: 4
    },
    SITE_TITLE: 'FIND-MY PARTS • Connecting You to the World of Spare Parts'
}

export const PAYMENT_CONSTANTS = {
    TL: 'TL',
    USD: 'USD',
    AMOUNT: {
        MONTHLY: 249.90,
        SIX_MONTHS: 1249.90,
        YEARLY: 1999.90,
        SHOP: {
            ONE_MONTH: 149.90,
            THREE_MONTHS: 349.90,
            SIX_MONTHS: 649.90
        }
    },
    OK_URL: 'https://www.find-myparts.com/api/api/user/subscription/success',
    FAIL_URL: 'https://www.find-myparts.com/api/api/user/subscription/fail',
    CALLBACK_URL: 'https://www.find-myparts.com/api/site/subscription/callbackurl'
}
