import Vue from 'vue'
import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { IPost } from '~/interfaces/post'

export interface BlogState {
    currentPost: IPost | null;
    postId: number | null;
}

export interface BlogAddPayload {
    post: IPost;
}

export interface BlogIdAddPayload {
    postId: number;
}

export interface BlogRemovePayload {
    postId: number;
}

function getDefaultState (): BlogState {
    return {
        currentPost: null,
        postId: null
    }
}

export const state = getDefaultState

export const mutations: MutationTree<BlogState> = {
    add (state, payload: BlogAddPayload): void {
        if (payload) {
            state.currentPost = payload.post
        }
    },
    navigate (state, payload: BlogIdAddPayload): void {
        if (payload) {
            state.postId = payload.postId
        }
    },
    remove (state): void {
        state.currentPost = null
        state.postId = null
    }
}

// noinspection JSUnusedGlobalSymbols
export const actions: ActionTree<BlogState, {}> = {
    async add ({ commit }: any, payload: BlogAddPayload): Promise<void> {
        await new Promise<void>((resolve) => {
            setTimeout(() => {
                commit('add', payload)
                resolve()
            }, 500)
        })
    },
    async remove ({ commit }: any): Promise<void> {
        await new Promise<void>((resolve) => {
            setTimeout(() => {
                commit('remove')
                resolve()
            }, 500)
        })
    },
    async navigate ({ commit }: any, payload: BlogIdAddPayload): Promise<void> {
        await new Promise<void>((resolve) => {
            setTimeout(() => {
                commit('navigate', payload)
                resolve()
            }, 500)
        })
    }
}

export const getters: GetterTree<BlogState, {}> = {
    currentPost (store) {
        return store.currentPost
    }
}
