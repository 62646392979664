






import { Vue, Component, Prop } from 'vue-property-decorator';
import { IProduct } from '~/interfaces/product';
import Rating from '~/components/shared/rating.vue';
import ProductGallery from '~/components/shared/product-gallery.vue';
import AppLink from '~/components/shared/app-link.vue';
import AsyncAction from '~/components/shared/async-action.vue';
import InputNumber from '~/components/shared/input-number.vue';
import Wishlist16Svg from '~/svg/wishlist-16.svg';
import { ValidationProvider } from 'vee-validate';
import { IUserAddress } from '~/interfaces/address';
import { Getter, Action } from 'vuex-class';
import StatusMessage from '~/components/shared/status-message.vue';
import OffersView from '~/components/shop/offers-list-view.vue';

export type ProductLayout = 'standard' | 'sidebar' | 'columnar' | 'quickview';

@Component({
    components: {
        Rating,
        AppLink,
        ProductGallery,
        AsyncAction,
        Wishlist16Svg,
        InputNumber,
        StatusMessage,
        OffersView
    }
})
export default class Product extends Vue {
    @Getter('auth/currentUser') user!: any;
    @Prop({ type: String, required: true }) readonly layout!: ProductLayout;
    @Prop({ type: Object, required: true }) readonly product!: IProduct;

}
