import { ActionTree, MutationTree } from 'vuex';
import { IProduct } from '~/interfaces/product';

export interface QuickviewState {
    open: boolean;
    product: IProduct | null;
    type: string;
}

interface QuickviewOpenPayload {
    productId: string;
    type: string;
}

interface QuickviewOpenSuccessPayload {
    product: IProduct;
    type: string;
}

function getDefaultState(): QuickviewState {
    return {
        open: false,
        product: null,
        type: ''
    };
}

export const state = getDefaultState;

// noinspection JSUnusedGlobalSymbols
export const mutations: MutationTree<QuickviewState> = {
    openSuccess(state, payload: QuickviewOpenSuccessPayload): void {
        state.open = true;
        state.product = payload.product;
        state.type = payload.type;
    },
    close(state) {
        state.open = false;
    }
};

let cancelPreviousRequest = () => {};

// noinspection JSUnusedGlobalSymbols
export const actions: ActionTree<QuickviewState, {}> = {
    async open({ commit }, payload: QuickviewOpenPayload) {
        cancelPreviousRequest();
        var type = payload.type
        if(type !== 'contact'){
            await new Promise<void>(resolve => {
                let canceled = false;
                // sending request to server, timeout is used as a stub
                const timer = setTimeout(() => {
                    this.$shopApi.getPartById(payload.productId).then(product => {
                        if (canceled) {
                            return;
                        }

                        if (product) {
                            commit('openSuccess', { product, type });
                        }

                        resolve();
                    });
                }, 350);

                cancelPreviousRequest = () => {
                    canceled = true;
                    clearTimeout(timer);
                    resolve();
                };
            });
        }
        else{
            var productId = payload.productId
            commit('openSuccess', { productId, type });
        }
    }
};
