








import { Vue, Component } from 'vue-property-decorator';
import { State, Getter } from 'vuex-class';
import { RootState } from '~/store';
import { HeaderLayout } from '~/store/options';
import Search from '~/components/header/search.vue';
import NavPanel from '~/components/header/nav-panel.vue';
import Topbar from '~/components/header/topbar.vue';
import AppLink from '~/components/shared/app-link.vue';
import LogoSvg from '~/svg/logo.svg';
import theme from '~/data/theme';
import Indicator from '~/components/header/indicator.vue';

@Component({
    components: { AppLink, NavPanel, Search, Topbar, LogoSvg, Indicator }
})
export default class Header extends Vue {
    theme = theme;

    @State((state: RootState) => state.options.headerLayout)
    layout!: HeaderLayout;

}
