






















































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { IProduct } from '~/interfaces/product';
import AsyncAction from '~/components/shared/async-action.vue';
import AppLink from '~/components/shared/app-link.vue';
import Rating from '~/components/shared/rating.vue';
import Quickview16Svg from '~/svg/quickview-16.svg';
import Wishlist16Svg from '~/svg/wishlist-16.svg';
import { Getter, Action } from 'vuex-class';
import { User } from '~/types/user';

@Component({
    components: {
        AsyncAction,
        Quickview16Svg,
        AppLink,
        Rating,
        Wishlist16Svg
    }
})
export default class OfferCard extends Vue {
    @Action('shop/resetFilters') resetFilters!: () => void;

    @Getter('auth/loggedIn') isLoggedIn!: boolean;
    @Getter('auth/currentUser') user!: any;

    @Prop({ type: Object, required: true }) readonly offer!: any;
    @Prop({ type: String, required: false }) readonly type!: any;
    @Prop({ type: Number, required: false }) readonly partStatus!: any;

    currentUser: User = {} as User;
    isSupplier: boolean = false;
    isLoading: boolean = false;
    offerEmitData: any = {};

    beforeMount() {
        if (this.isLoggedIn) {
            this.currentUser = { ...this.user?.data };
            for (let i = 0; i < this.currentUser.roles.length; i++) {
                if (this.currentUser.roles[i] == 'ROLE_SUPPLIER') {
                    this.isSupplier = true;
                }
            }
        }
    }

    formatDate(createdDate: any) {
        const locale = Intl.DateTimeFormat().resolvedOptions().locale;
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const options: Intl.DateTimeFormatOptions = {
            timeZone: timeZone,
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        };
        const date = new Date(createdDate + ' UTC+3');
        const formatter = date.toLocaleString(locale, options);
        return formatter;
    }

    async acceptOffer(offerId: any) {
        this.isLoading = true;
        this.$shopApi.acceptOffer(offerId).then(response => {
            Vue.notify({
                type: 'success',
                text: response.message
            });
            this.$store.commit('quickview/close');
            this.resetFilters();
        });
    }

    openContactBox() {
        if (this.isSupplier) {
            var params = {
                oid: this.offer.id
            };
            this.$shopApi.getPartRequester(params).then(
                response => {
                    let part = response.part;
                    this.offerEmitData = part.parts[0];
                    this.$emit('offerEmit', this.offerEmitData);
                },
                error => {
                    console.log(error);
                }
            );
        }
    }
}
