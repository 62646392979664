




























import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { BModal } from 'bootstrap-vue'
import { RootState } from '~/store'
import { IProduct } from '~/interfaces/product'
import Product from '~/components/shared/product.vue'
import Offer from '~/components/shared/offer.vue'
import ShopProduct from '~/components/shared/shop-product.vue'
import RequesterContact from '~/components/shared/requester-contact.vue'
import Cross20Svg from '~/svg/cross-20.svg'

@Component({
    components: { Cross20Svg, ShopProduct, Product, Offer, RequesterContact }
})
export default class Quickview extends Vue {
    @State((state: RootState) => state.quickview.open) isOpen!: boolean
    @State((state: RootState) => state.quickview.product) product!: IProduct | null
    @State((state: RootState) => state.quickview.type) type!: string

    @Ref() modal!: BModal

    showProduct: boolean = false

    @Watch('isOpen')
    onIsOpenChange (newValue: boolean) {
        if (newValue) {
            this.modal.show()
        } else {
            this.modal.hide()
        }
    }

    handleModalShow () {
        setTimeout(() => {
            this.showProduct = true
        }, 50)
    }
}

