const dataSearchDepartments: any = [
    { id: 1,name: 'Construction'},
    { id: 2,name: 'Mining'},
    { id: 3,name: 'Automotive'},
    { id: 4,name: 'Heavy Machinery Sale'},
    { id: 5,name: 'Marine'}
];

dataSearchDepartments.sort(function(a: any, b: any) {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
});

export default dataSearchDepartments;
