import { MutationTree } from 'vuex'

export type HeaderLayout = 'default' | 'compact'

export interface OptionsState {
    headerLayout: HeaderLayout,
}

function getDefaultState (): OptionsState {
    return {
        headerLayout: 'default',
    }
}

export const state = getDefaultState

// noinspection JSUnusedGlobalSymbols
export const mutations: MutationTree<OptionsState> = {
    setHeaderLayout (state, headerLayout: HeaderLayout) {
        state.headerLayout = headerLayout
    },
}
