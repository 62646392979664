




























































































































































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { IProduct } from '~/interfaces/product';
import AsyncAction from '~/components/shared/async-action.vue';
import AppLink from '~/components/shared/app-link.vue';
import Rating from '~/components/shared/rating.vue';
import Quickview16Svg from '~/svg/quickview-16.svg';
import Wishlist16Svg from '~/svg/wishlist-16.svg';
import Cart16Svg from '~/svg/cart-16.svg'
import { Getter } from 'vuex-class';
import { User } from '~/types/user';
import InputNumber from '~/components/shared/input-number.vue';
import * as CONSTANTS from '~/config/constants';
import Cross20Svg from '~/svg/cross-20.svg';
import Login from '~/pages/account/login.vue';

export type ProductCardLayout =
    | 'grid-sm'
    | 'grid-nl'
    | 'grid-lg'
    | 'list'
    | 'horizontal';

@Component({
    components: {
        AsyncAction,
        Quickview16Svg,
        AppLink,
        Rating,
        Wishlist16Svg,
        Cart16Svg,
        InputNumber,
        Cross20Svg,
        Login
    }
})
export default class ProductCard extends Vue {
    @Getter('auth/loggedIn') isLoggedIn!: boolean;
    @Getter('auth/currentUser') user!: any;

    @Prop({ type: Object, required: true }) readonly product!: IProduct;
    @Prop({ type: String, default: () => 'request' }) shopType!: string;
    @Prop({ type: String, default: () => undefined })
    readonly layout!: ProductCardLayout;
    @Prop({ type: String, default: () => 'listoffer' }) readonly type!: string;
    @Prop({ type: Number, required: false }) readonly saleId!: string;
    roles: any = CONSTANTS.MAIN_CONSTANTS.ROLES;

    isAdmin: boolean = false;
    currentUser: User = {} as User;
    imgExtension: string = "jpg";
    noImg: boolean = true;
    isLoading: boolean = false;
    confirmedSaleQuantity: number = 1;
    sellerContactAddress: any = {};
    randomForModal: number = -1

    async beforeMount() {
        this.randomForModal = Math.floor(Math.random() * 100)
        if(this.product.imagesCount > 0){
            var imageUrls = JSON.parse(this.product.imageUrls);
            this.imgExtension = imageUrls[0];
            this.noImg = false;
        }
        if(this.isLoggedIn){
            this.currentUser = { ...this.user?.data };
            for (let i = 0; i < this.currentUser.roles.length; i++) {
                if (this.currentUser.roles[i] == this.roles.ADMIN){
                    this.isAdmin = true
                }
            }
        }
    }

    confirmSell(product: any){
        this.isLoading = true
        this.$bvModal.show('confirm-sale-modal-'+ product.id)
        setTimeout(() => {
            this.isLoading = false
        }, 3000);
    }

    confirmWithQty(){
        const qty = this.confirmedSaleQuantity
        if(qty < this.product.qty && qty > 0){
            const _product = {...this.product}
            const qty = this.confirmedSaleQuantity
            _product.qty = qty
            this.$userApi.checkUserSubscription('2').then(
                async response => {
                    if (response.Subscription) {
                        this.$store.dispatch('cart/confirmSaleAction', _product).then(
                            response => {
                                this.$emit('refreshMyShopList', true)
                                this.isLoading = false;
                                this.$bvModal.hide('confirm-sale-modal-'+ this.product.id)
                            },
                            error => {
                            }
                        );
                    } else {
                        Vue.notify({
                            type: 'error',
                            text: 'Seller Subscription required for this operation.'
                        });
                        this.isLoading = false;
                    }
                },
                err => {
                    console.log('err:', err);
                }
            );
        }
    }

    confirmAll(){
        const qty = this.product.sales.length
        const _product = {...this.product}
        _product.qty = qty
        this.$userApi.checkUserSubscription('2').then(
            async response => {
                if (response.Subscription) {
                    this.$store.dispatch('cart/confirmSaleAction', _product).then(
                        response => {
                            this.$emit('refreshMyShopList', true)
                            Vue.notify({
                                type: 'success',
                                text: 'Product status changed to passive !'
                            });
                            this.isLoading = false;
                            this.$bvModal.hide('confirm-sale-modal-'+ this.product.id)
                        },
                        error => {
                        }
                    );
                } else {
                    Vue.notify({
                        type: 'error',
                        text: 'Seller Subscription required for this operation.'
                    });
                    this.isLoading = false;
                }
            },
            err => {
                console.log('err:', err);
            }
        );
    }

    confirmDelete(productId: number){
        this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this item? This action cannot be undone !', {
          title: 'Please Confirm',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'warning',
          okTitle: 'Confirm',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
          html: true
        })
          .then(value => {
              if(value){
                this.$shopApi.deletePart(productId).then( response => {
                    this.isLoading = false
                    this.$emit('refreshAdminPartList', true)
                },
                error => {
                    this.isLoading = false
                    console.log(error)
                })
            }
            else{
                this.isLoading = false
            }
          })
          .catch(err => {
            // An error occurred
            console.log('err:', err)
          })
    }

    formatDate(createdDate: any){
        const locale = Intl.DateTimeFormat().resolvedOptions().locale
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

        const options: Intl.DateTimeFormatOptions = {
            timeZone: timeZone,
            day: "numeric",
            month: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
        }
        const date = new Date(createdDate + ' UTC+3')
        const formatter = date.toLocaleString(locale, options);
        return formatter;
    }

    async openContactBox(partId: number, saleId: number){
        await this.$shopApi.getSellerAddress(partId, saleId).then(
            response => {
            this.sellerContactAddress = response
        },
            error => {

        })
    }
}
