import createPersistedState from 'vuex-persistedstate'
import { Plugin } from '@nuxt/types'

const plugin: Plugin = ({ store }) => {
    createPersistedState({
        key: 'findmyparts',
        paths: [
            'auth',
            'currency',
            'wishlist',
            'cart',
            'blog'
        ]
    })(store)
}

export default plugin
