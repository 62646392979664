import axios, { AxiosResponse } from 'axios';
import * as CONSTANTS from './constants';

const httpClient = axios.create({
    baseURL: CONSTANTS.MAIN_CONSTANTS.SERVER_URL + '/api',
    headers: {
        'Content-type': 'application/json'
    }
});

const responseInterceptor = (response: any) => {
    return response;
  }
const errorInterceptor = (error : any)=> {
  // check if it's a server error
    if (!error.response) {
      return Promise.reject(error);
    }

    // all the other error responses
    switch (error.response.status) {
      case 400:
        //notify.warn('Nothing to display', 'Data Not Found');
        break;

      case 401: // authentication error, logout the user
      case 403: // authentication error, logout the user
        if(error.response?.data?.errCode !== 104){
            window.$nuxt.$store.dispatch('auth/logout');
            window.$nuxt.$router.push('/account/login');
        }
        break;

  default:
    console.error(error.response.status, error.message);
    //notify.error('Server Error');
   }
    return Promise.reject(error);
  }
  httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

  export default httpClient;