















































import { Vue, Component, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { RootState } from '~/store'
import Footer from '~/components/footer/footer.vue'
import Header from '~/components/header/header.vue'
import MobileHeader from '~/components/mobile/mobile-header.vue'
import MobileMenu from '~/components/mobile/mobile-menu.vue'
import Quickview from '~/components/shared/quickview.vue'

@Component({
    components: {
        MobileMenu,
        MobileHeader,
        Header,
        Footer,
        Quickview
    },
    head (this: Layout) {
        return {
            bodyAttrs: {
                class: this.bodyClasses
            }
        }
    }
})

export default class Layout extends Vue {
    bodyClasses = ['disable-transitions']
    isMobile = window.innerWidth < 992;

    async mounted () {
        await this.$nextTick()

        this.bodyClasses = []
    }
    created(){
        window.addEventListener('resize', (e: any)=>{
            this.isMobile = e?.currentTarget?.innerWidth < 992;
    })
    }
}

