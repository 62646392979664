var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        'search',
        ("search--location--" + _vm.location),
        {
            'search--has-suggestions': _vm.hasSuggestions,
            'search--suggestions-open': _vm.suggestionsOpen
        }
    ]},[_c('div',{staticClass:"search__body"},[_c('form',{staticClass:"search__form"},[_c('input',{ref:"input",staticClass:"search__input",attrs:{"name":"search","placeholder":"Search part","aria-label":"Site search","type":"text","autocomplete":"off"},domProps:{"value":_vm.query}}),_vm._v(" "),_c('button',{staticClass:"search__button search__button--type--submit",attrs:{"type":"submit"}},[_c('Search20Svg')],1),_vm._v(" "),(_vm.location === 'mobile-header')?_c('button',{staticClass:"search__button search__button--type--close",attrs:{"type":"button"},on:{"click":_vm.onCloseClick}},[_c('Cross20Svg')],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"search__border"})]),_vm._v(" "),_c('div',{class:[
                'search__suggestions',
                'suggestions',
                ("suggestions--location--" + _vm.location)
            ]},[_c('ul',{staticClass:"suggestions__list"},_vm._l((_vm.suggestedProducts),function(product){return _c('li',{key:product.id,staticClass:"suggestions__item"},[_c('div',{staticClass:"suggestions__item-image product-image"},[_c('div',{staticClass:"product-image__body"},[_c('img',{staticClass:"product-image__img",attrs:{"src":_vm.$url.img(product.images[0]),"alt":""}})])]),_vm._v(" "),_c('div',{staticClass:"suggestions__item-info"},[_c('NuxtLink',{staticClass:"suggestions__item-name",attrs:{"to":_vm.$url.product(product)},nativeOn:{"click":function($event){return _vm.closeSuggestion()}}},[_vm._v("\n                            "+_vm._s(product.name)+"\n                        ")]),_vm._v(" "),_c('div',{staticClass:"suggestions__item-meta"},[_vm._v("\n                            SKU: "+_vm._s(product.sku)+"\n                        ")])],1)])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }