
import Vue from 'vue';
import io from 'socket.io-client';
import VueSocketIOExt from 'vue-socket.io-extended';
const socket = io('https://www.find-myparts.com:3001');
//const socket = io('http://localhost:3001');

export default ({ store }: any) => {
  Vue.use(VueSocketIOExt, socket, { store });
}
