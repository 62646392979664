














import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class StatusMessage extends Vue {
    @Prop({ type: String, default: () => 'initial' }) status!: string;
    @Prop({ type: String, default: () => '' }) message!: string;
}
