
















import { Vue, Component, Prop } from 'vue-property-decorator'
import Rating13x12Svg from '~/svg/rating-13x12.svg'

@Component({
    components: { Rating13x12Svg }
})
export default class Rating extends Vue {
    @Prop({ type: Number, default: () => 0 }) readonly value!: number

    stars = [1, 2, 3, 4, 5]
}

