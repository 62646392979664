import { INav } from '~/interfaces/menus/nav'

const dataHeaderDepartments: INav = [
    { title: 'CONSTRUCTION', url: '1' },
    { title: 'MINING', url: '2' },
    { title: 'AUTOMOTIVE', url: '3' },
    { title: 'HEAVY MACHINERY SALE', url: '4' },
    { title: 'MARINE', url: '5' }
]

dataHeaderDepartments.sort(function (a:any, b:any) {
    return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
});

export default dataHeaderDepartments
