import { MutationTree, ActionTree, GetterTree } from 'vuex';
import { Auth } from '@/interfaces/auth';
import { User, LoginUser, RegisterUser } from '@/types/user';

export interface AuthState {
    current: Auth;
}

function getDefaultState(): AuthState {
    return {
        current: {
            status: { loggedIn: false },
            user: {} as User
        }
    };
}

export const state = getDefaultState;

export const actions: ActionTree<AuthState, {}> = {
    async register({ commit }, user: RegisterUser): Promise<any> {
        return await this.$userApi
            .Register(user)
            .then(response => {
                commit('registerSuccess');
                return Promise.resolve(response);
            })
            .catch(error => {
                commit('registerFailure');
                if (error.response) {
                    return Promise.reject(error?.response?.data?.message);
                } else if (error.message) {
                    return Promise.reject(error.message);
                } else if (error.request) {
                    return Promise.reject(error.request);
                }
            });
    },
    async login({ commit }, user: LoginUser): Promise<any> {
        return await this.$userApi
            .Login(user)
            .then(response => {
                commit('loginSuccess', response);
                return Promise.resolve(response);
            })
            .catch(error => {
                commit('loginFailure');
                if (error.response) {
                    return Promise.reject(error?.response?.data?.message);
                } else if (error.message) {
                    return Promise.reject(error.message);
                } else if (error.request) {
                    return Promise.reject(error.request);
                }
            });
    },
    async updateUser({ commit }): Promise<any> {
        return await this.$userApi
            .getUser()
            .then(response => {
                commit('updateUserSuccess', response);
                return Promise.resolve(response);
            })
            .catch(error => {
                commit('loginFailure');
                if (error.response) {
                    return Promise.reject(error?.response?.data?.message);
                } else if (error.message) {
                    return Promise.reject(error.message);
                } else if (error.request) {
                    return Promise.reject(error.request);
                }
            });
    },
    logout({ commit }) {
        commit('logout');
    }
};

export const mutations: MutationTree<AuthState> = {
    loginSuccess(state, user) {
        state.current.status.loggedIn = true;
        delete user.config.data;
        state.current.user = user;
        location.reload();
    },
    loginFailure(state) {
        state.current.status.loggedIn = false;
        state.current.user = {} as User;
    },
    logout(state) {
        state.current.status.loggedIn = false;
        state.current.user = {} as User;
    },
    registerSuccess(state) {
        state.current.status.loggedIn = false;
    },
    registerFailure(state) {
        state.current.status.loggedIn = false;
    },
    updateUserSuccess(state, user) {
        user.data.accessToken = state.current.user.data.accessToken;
        delete user.config.data;
        state.current.user = user
    }
};

export const getters: GetterTree<AuthState, {}> = {
    loggedIn(store) {
        return store.current.status.loggedIn;
    },
    currentUser(store) {
        return store.current.user;
    }
};
