/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedLocalSymbols

import { Context, Plugin } from '@nuxt/types';
import authHeader from '~/services/auth-header';
import axios from '../config/http-common';
import { IBComment } from '~/interfaces/comment';

function make(context: Context) {
    return {
        /**
         * Get a post by id.
         */
        getPostById: async (postId: any): Promise<any> => {
            return await axios.get('blog/id/' + postId, { headers: authHeader() });
        },
        /**
         * Get a post by slug
         */
        getPostBySlug: async (slug: any): Promise<any> => {
            return await axios.get('blog/slug/' + slug, { headers: authHeader() });
        },
        /**
         * Get a posts by category name
         */
        getPostsByCategory: async (slug: any): Promise<any> => {
            return axios.get('blog/category/' + slug, { headers: authHeader() }).then(
                response => {
                    return response.data;
                },
                error => {
                    return error.response.data.message;
                }
            );
        },
        /**
         * Get comments of a post by postId.
         */
        getCommentsByPostId: async (postId: number, page: number): Promise<any> => {
            return await axios.get('/blog/comments/' + postId + '/' +  page, { headers: authHeader() });
        },
        /**
         * Get an author with aid.
         */
        getAuthor: async (authorId: any): Promise<any> => {
            return await axios.get('auhtor/' + authorId, { headers: authHeader() });
        },
        /**
         * Update an author.
         */
        updateUser: (authorId: string, author: any): Promise<any> => {
            return axios.put('author/' + authorId, author, { headers: authHeader() }).then(
                response => {
                    return response.data.message;
                },
                error => {
                    return error.response.data.message;
                }
            );
        },
        /**
         * Create a new post.
         */
        createPost: async (post: any): Promise<any> => {
            var params = {
                title: post.title,
                description: post.description,
                categoryId: post.categoryId,
                content: post.content,
                images: post.images
                // tag: post.tags,
            };
            try {
                const response = await axios.post(
                    '/blog/create',
                    params, { headers: authHeader() }
                );
                return response;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Upload a new image for post content.
         */
        uploadPostImage: async (images?: any): Promise<any> => {
            var formData = new FormData();

            if(images){
                for (let j = 0; j < images.length; j++) {
                    formData.append('images', images[j]);
                  }
            }

            try {
                const response = await axios.post(
                    '/blog/uploadBlogImage',
                    formData, { headers: authHeader() }
                );
                return response;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Get user posts.
         */
        getUserPosts: async (userId: string): Promise<any> => {
            try {
                const response = await axios.get(
                    '/post/user/' + userId, { headers: authHeader() }
                );
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Get all posts.
         */
        getPosts: async (page: number): Promise<any> => {
            try {
                const response = await axios.get(
                    '/blog?page=' + page.toString(), { headers: authHeader() }
                );
                return response.data;
            }
            catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Get Latest posts.
         */
        getLatestPosts: async (): Promise<any> => {
            try {
                const response = await axios.get(
                    '/blog/latest', { headers: authHeader() }
                );
                return response.data;
            }
            catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Create a comment
         */
        createComment: async (comment: IBComment): Promise<any> => {
            var params: IBComment = {
                postId: comment.postId,
                content: comment.content
            };
            if(comment.commentId){
                params.commentId = comment.commentId
            }
            try {
                const response = await axios.post(
                    '/blog/comment',
                    params, { headers: authHeader() }
                );
                return response.data;
            }
            catch (error: any) {
                throw new Error(error);
            }
        }
    };
}

declare module 'vue/types/vue' {
    interface Vue {
        $blogApi: ReturnType<typeof make> & Context;
    }
}

declare module '@nuxt/types' {
    interface Context {
        $blogApi: ReturnType<typeof make> & Context;
    }
}

declare module 'vuex/types/index' {
    interface Page<S> {
        $blogApi: ReturnType<typeof make> & Context;
    }
}

declare module 'vuex/types/index' {
    interface Store<S> {
        $blogApi: ReturnType<typeof make> & Context;
    }
}

const plugin: Plugin = (context, inject) => {
    inject('blogApi', make(context));
};

export type blogApi = ReturnType<typeof make>;

export default plugin;
