































import { Vue, Component } from 'vue-property-decorator';
import FooterContacts from '~/components/footer/footer-contacts.vue';
import FooterLinks from '~/components/footer/footer-links.vue';
// import FooterNewsletter from '~/components/footer/footer-newsletter.vue'
import ToTop from '~/components/footer/to-top.vue';
import theme from '~/data/theme';
// import SocialLinks from '~/components/shared/social-links.vue'

@Component({
    components: { ToTop, FooterLinks, FooterContacts }
})
export default class Footer extends Vue {
    theme = theme;
}
