import { Vue, Component, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay } from 'swiper'
import { SwiperOptions } from 'swiper/types/swiper-options'
import { IDirection } from '~/interfaces/language'
SwiperClass.use([Pagination, Mousewheel, Autoplay])
import 'swiper/swiper-bundle.css'

@Component
export default class Carousel extends Vue {
    @Prop({ type: Object, default: () => ({}) }) options!: SwiperOptions

    @Getter('locale/direction') direction!: IDirection

    swiper: SwiperClass | null = null

    mounted () {
        if (!(this.$el instanceof HTMLElement)) {
            return
        }

        this.swiper = new SwiperClass(this.$el, Object.assign({}, {
            loop: true,
            autoplay: {
            delay: 5000,
            },
            navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            watchOverflow: true,
        }, this.options))
    }
}
