


























































































































































































































import { Vue, Component } from 'vue-property-decorator';
import AppLink from '~/components/shared/app-link.vue';
import Check9x7Svg from '~/svg/check-9x7.svg';
import { LoginUser, User } from '~/types/user';
import { Getter } from 'vuex-class';
import { Socket } from 'vue-socket.io-extended';

@Component({
    components: { AppLink, Check9x7Svg }
})
export default class AccountMenu extends Vue {
    loginUser: any = {} as LoginUser;
    message: string = '';
    passwordType: string = 'password';
    loginStatus: string = 'initial';

    requestNotifyCount: number = 0;
    offerNotifyCount: number = 0;
    purchaseNotifyCount: number = 0;
    shopNotifyCount: number = 0;
    isConnected: boolean = false;

    @Getter('auth/currentUser') user!: any;
    @Getter('auth/loggedIn') isLoggedIn!: boolean;

    async onSubmit() {
        this.loginStatus = 'loading';
        await this.$store.dispatch('auth/login', this.loginUser).then(
            response => {
                this.loginUser = {};
                this.loginStatus = 'success';
            },
            error => {
                this.loginStatus = 'error';
                this.message = error;
            }
        );
    }

    mounted() {
        if (this.isLoggedIn) {
            this.connect();
        }
    }
    socketConnection() {
        // eslint-disable-next-line
        this.$socket.client.emit('userConnected', {
            user_id: this.user.data.id
        });
    }

    @Socket()
    connect() {
        if (this.isLoggedIn) {
            this.isConnected = true;
            this.socketConnection();
        }
    }

    disconnect() {
        if (this.isLoggedIn) {
            this.isConnected = false;
        }
    }

    userDisconnected() {
        if (this.isLoggedIn) {
            this.isConnected = false;
        }
    }

    @Socket('notify')
    notify(data: any) {
        if (this.isLoggedIn) {
            this.requestNotifyCount = data.requestNotifyCount;
            this.offerNotifyCount = data.offerNotifyCount;
            this.purchaseNotifyCount = data.purchaseNotifyCount;
            this.shopNotifyCount = data.shopNotifyCount;
        }
    }
}
