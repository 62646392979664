/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedLocalSymbols

import { Context, Plugin } from '@nuxt/types';
import { IFilterValues, IListOptions } from '~/interfaces/list';
import { IProduct, IProductsList } from '~/interfaces/product';
import { ICategory } from '~/interfaces/category';
import { IUserAddress } from '~/interfaces/address';
import authHeader from '~/services/auth-header';
import axios from '../config/http-common';
var qs = require('qs');
import { MAIN_CONSTANTS } from '~/config/constants';
import { ActionTree, MutationTree } from 'vuex';
export interface GetCategoriesOptions {}
export interface GetRelatedProductsOptions {
    limit?: number;
}

export interface GetProductsOptions {
    limit?: number;
    category?: string;
}

export type GetSuggestionsOptions = {
    limit?: number;
    category?: string;
};

/**
 * @param context Can be used, for example, to access the axios plugin.
 */
function make(context: Context) {
    return {
        /**
         * Returns array of categories.
         */
        getCategories: async (): Promise<any> => {
            try {
                const response = await axios.get('/shop/category');
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Returns category by id
         */
        getCategoryById: async (id: any): Promise<any> => {
            try {
                const response = await axios.get('/shop/category/' + id);
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Get offers of a request
         */
        getPartOffers: async (pid: any, options: any): Promise<any> => {
            try {
                const response = await axios.post('/offer/getPartOffers/' + pid , options, { headers: authHeader() });
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Returns part requester address info if request is accepted
         */
        getPartRequester: async (params: any): Promise<any> => {
            try {
                const response = await axios.post('/offer/getPartRequester', params, { headers: authHeader() });
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Returns product.
         */
        getPartById: async (productId: any): Promise<any> => {
            try {
                const response = await axios.get('/part/' + productId, { headers: authHeader() });
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Accept an offer.
         */
        acceptOffer: async (offerId: any): Promise<any> => {
            try {
                const response = await axios.get('/offer/accept/' + offerId, { headers: authHeader() });
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Return products list.
         */
        getProductsList: async (
            options: any = {},
            filters: any = {},
            type: string
        ): Promise<any> => {
            /**
             * - page            = options.page
             * - limit           = options.limit
             * - filter_category = filters.category
             */
            const params: any = { ...options };

            Object.keys(filters).forEach(slug => {
                params[`filter_${slug}`] = filters[slug];
            });
            params.type = type

            try {
                const response = await axios.post('/part/search', params);
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Return latest products list.
         */
        getLatestParts: async (
            // limit: number,
            // type: string
        ): Promise<any> => {
            try {
                const response = await axios.post('/part/latest');
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Return user offer list.
         */
        getUserOffers: async (
            options: any = {}
        ): Promise<any> => {
            const params: any = { ...options };

            try {
                const response = await axios.post('/offer/userOffers', params, { headers: authHeader() });
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Verify contact form
         */
         contact: async (
            token: any
        ): Promise<any> => {

            try {
                const response = await axios.post('/contact', token);
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Returns brands of a category
         */
        getBrands: async (
            categoryId: number
        ): Promise<any> => {
            try {
                const response = await axios.get('/shop/brands/' + categoryId);
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Shop system buy item
         */
        buyItem: async (
            product: any
        ): Promise<any> => {
            const productId = product?.id
            try {
                const headers = authHeader();
                const response = await axios.get('/shop/item/' + productId + '/buy', { headers: headers } );
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Accept a sale.
         */
        acceptSale: async (saleId: any): Promise<any> => {
            try {
                const response = await axios.get('/shop/sale/accept/' + saleId, { headers: authHeader() });
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         *
         * Confirm a sale.
         */
        confirmSale: async (saleId: any, qty: number): Promise<any> => {
            try {
                const response = await axios.post('/shop/item/' + saleId + '/confirm', {qty: qty}, { headers: authHeader() });
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Get sale requests of a user
         */
        getUserSales: async (options: any): Promise<any> => {
            try {
                const response = await axios.post('/shop/userSales', options, { headers: authHeader() });
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Delete a part.
         */
        deletePart: async (pid: number): Promise<any> => {
            try {
                const response = await axios.get('/part/' + pid + '/delete', { headers: authHeader() });
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Get address of a part for a sale
         */
         getSellerAddress: async (pid: any, sid: any): Promise<any> => {
            try {
                const response = await axios.get('/shop/sale/' + pid + '/' + sid + '/sellerAddress', { headers: authHeader() });
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
    };
}

declare module 'vue/types/vue' {
    interface Vue {
        $shopApi: ReturnType<typeof make> & Context;
    }
}

declare module '@nuxt/types' {
    interface Context {
        $shopApi: ReturnType<typeof make> & Context;
    }
}

declare module 'vuex/types/index' {
    interface Store<S> {
        $shopApi: ReturnType<typeof make> & Context;
    }
}

const plugin: Plugin = (context, inject) => {
    inject('shopApi', make(context));
};

export type ShopApi = ReturnType<typeof make>;

export default plugin;
