import { INav } from '~/interfaces/menus/nav';

const dataHeaderNavigation: INav = [
    {
        title: 'Home',
        url: '/',
        icon: 'fas fa-home'
    },
    {
        title: 'Requests',
        url: '/request',
        icon: 'fas fa-comment-dots'
    },
    {
        title: 'Marketplace',
        url: '/shop',
        icon: 'fas fa-shopping-cart'
    },
    {
        title: 'How to sell?',
        icon: 'fas fa-store',
        url: '/site/how-to-sell',
    },
    {
        title: 'Contact',
        icon: 'fas fa-envelope',
        url: '/site/contact-us',
    }
];

export default dataHeaderNavigation;
