import VueI18n from 'vue-i18n';
import { Context, Plugin } from '@nuxt/types';
import { IProduct } from '~/interfaces/product';
import { IUserAddress } from '~/interfaces/address';
import { IOrder } from '~/interfaces/order';
import { ICategory } from '~/interfaces/category';
import { INewPart } from '~/interfaces/part';
import * as CONSTANTS from '../config/constants';


function make(context: Context) {
    return {
        home() {
            return '/';
        },
        category(category: any, shopType: string) {
            return context.$url.partCategory(category, shopType);
        },
        blogCategory(category: string) {
            return '/blog/category/' + category;
        },
        partCategory(category: any, shopType: string) {
            return `/${shopType}/filter_category=${category.id}`;
        },
        requestList() {
            return '/request';
        },
        shopList() {
            return '/shop';
        },
        product(part: Pick<IProduct, 'id'>) {
            return `/parts/${part.id}`;
        },
        cart() {
            return '/account/cart';
        },
        checkout() {
            return '/checkout';
        },
        wishlist() {
            return '/account/favorites';
        },
        trackOrder() {
            return '/track-order';
        },
        signIn() {
            return '/account/login';
        },
        signUp() {
            return '/account/register';
        },
        signOut() {
            return '/account/login';
        },
        adminPage() {
            return '/account/admin-page';
        },
        account() {
            return '/account/dashboard';
        },
        accountDashboard() {
            return '/account/dashboard';
        },
        accountProfile() {
            return '/account/profile';
        },
        accountOrders() {
            return '/account/orders';
        },
        accountOrder(order: Pick<IOrder, 'id'>) {
            return `/account/requests/${order.id}`;
        },
        accountRequest(order: Pick<IOrder, 'id'>) {
            return `/account/requests/${order.id}`;
        },
        createAddress() {
            return '/account/addresses/create';
        },
        createPart() {
            return '/parts/create';
        },
        createPost() {
            return '/blog/create';
        },
        createShopPart() {
            return '/shop/parts/create';
        },
        createPartSuccess() {
            return '/parts/success';
        },
        createPostSuccess() {
            return '/blog/success';
        },
        createShopPartSuccess() {
            return '/shop/parts/success';
        },
        accountAddresses() {
            return '/account/addresses';
        },
        accountAddress(address: Pick<IUserAddress, 'id'>) {
            return `/account/addresses/${address.id}`;
        },
        accountPassword() {
            return '/account/password';
        },
        lang(path: string) {
            const locale = context.store.state.locale.current;

            if (path[0] !== '/') {
                path = `/${path}`;
            }

            if (!context.app.i18n) {
                return path;
            }

            const i18n = context.app.i18n as VueI18n.I18nOptions;

            if (locale === i18n.fallbackLocale) {
                return path;
            }

            return `/${locale}${path}`;
        },
        isExternal(path: string): boolean {
            return /^(https?:)?\/\//.test(path);
        },
        anyLink(path: string) {
            return context.$url.isExternal(path)
                ? path
                : this.base(context.$url.lang(path));
        },
        blog() {
            return '/blog';
        },
        blogPost() {
            return '/blog/post-classic';
        },
        toPost(postUrl: string) {
            return '/blog/' + postUrl;
        },
        about() {
            return '/site/about-us';
        },
        contacts() {
            return '/site/contact-us';
        },
        terms() {
            return '/site/terms';
        },
        howitworks() {
            return '/site/how-it-works';
        },
        forsuppliers() {
            return '/site/become-a-supplier';
        },
        sellWithUs() {
            return '/site/how-to-sell';
        },
        base(url: string) {
            if (url[0] === '/') {
                return context.base + url.substr(1);
            }

            return url;
        },
        partimg(url: string) {
            return this.base(CONSTANTS.MAIN_CONSTANTS.BASE_URL + url);
        },
        postimg(url: string) {
            return this.base(CONSTANTS.MAIN_CONSTANTS.BASE_URL + '/public/images/tmp/' + url);
        },
        postCardimg(url: string, postId: number) {
            return this.base(CONSTANTS.MAIN_CONSTANTS.BASE_URL + '/public/images/blog/' + postId + '/' + url);
        },
        img(url: string) {
            return this.base(url);
        },
        avatarImg(url: string) {
            return this.base(CONSTANTS.MAIN_CONSTANTS.BASE_URL + '/public/images/avatar/' + url);
        },
        requests() {
            return '/requests';
        },
        myRequests() {
            return '/account/my-requests';
        },
        myOffers() {
            return '/account/my-offers';
        },
        registerBonus() {
            return '/site/register-bonus';
        },
        spark() {
            return 'https://www.sparkdijital.com';
        },
        notification() {
            return '/account/notification';
        },
        myPurchases() {
            return '/account/my-purchases';
        },
        myShop() {
            return '/account/my-shop';
        },
        ourWriters() {
            return '/site/our-writers';
        },
    };
}

declare module 'vue/types/vue' {
    interface Vue {
        $url: ReturnType<typeof make> & Context;
    }
}

declare module '@nuxt/types' {
    interface Context {
        $url: ReturnType<typeof make> & Context;
    }
}

const plugin: Plugin = (context, inject) => {
    inject('url', make(context));
};

export default plugin;
