

































import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import ArrowRoundedLeft8x13Svg from '~/svg/arrow-rounded-left-8x13.svg'
import ArrowRoundedRight8x13Svg from '~/svg/arrow-rounded-right-8x13.svg'

@Component({
    components: { ArrowRoundedLeft8x13Svg, ArrowRoundedRight8x13Svg }
})
export default class Pagination extends Vue {
    @Prop({ type: Number, default: () => 1 }) readonly siblings!: number
    @Prop({ type: Number, default: () => 1 }) readonly current!: number
    @Prop({ type: Number, default: () => 1 }) readonly total!: number

    get pages () {
        const pages = []
        const min = Math.max(
            1,
            this.current - this.siblings - Math.max(
                0,
                this.siblings - this.total + this.current
            )
        )
        const max = Math.min(this.total, min + this.siblings * 2)

        for (let i = min; i <= max; i += 1) {
            pages.push(i)
        }

        return pages
    }

    @Emit('page-change')
    setPage (value: number) {
        if (value < 1 || value > this.total || value === this.current) {
            return
        }

        return value
    }
}

