import { IMobileMenu } from '~/interfaces/menus/mobile-menu';

const dataMobileMenu: IMobileMenu = [
    {
        title: 'Home',
        type: 'link',
        url: '/',
        icon: 'fas fa-home'
    },
    {
        title: 'Marketplace',
        type: 'link',
        url: '/shop',
        icon: 'fas fa-shopping-cart'
    },
    {
        title: 'Part Requests',
        type: 'link',
        url: '/request',
        icon: 'fas fa-comment-dots'
    },
    {
        title: 'How to sell?',
        type: 'link',
        icon: 'fas fa-store',
        url: '/site/how-to-sell'
    },
    {
        title: 'Contact',
        type: 'link',
        url: '/site/contact-us',
        icon: 'fas fa-envelope'
    }
];

export default dataMobileMenu;
