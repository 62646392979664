































































































import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import { RootState } from '~/store';
import { WishlistState } from '~/store/wishlist';
import { CartState } from '~/store/cart';
import Sticky from '~/services/sticky';
import departments from '~/services/departments';

import Departments from '~/components/header/departments.vue';
import NavLinks from '~/components/header/nav-links.vue';
import AccountMenu from '~/components/header/account-menu.vue';
import Notification from '~/components/header/notification.vue';
import Indicator from '~/components/header/indicator.vue';
import AppLink from '~/components/shared/app-link.vue';
import Search from '~/components/header/search.vue';
import SearchScope from '~/components/header/search-scope.vue';

import LogoSmallSvg from '~/svg/logo-small.svg';
import Heart20Svg from '~/svg/heart-20.svg';
import Cart20Svg from '~/svg/cart-20.svg';
import Person20Svg from '~/svg/person-20.svg';
import Search20Svg from '~/svg/search-20.svg';
import Cross20Svg from '~/svg/cross-20.svg';
import { Socket } from 'vue-socket.io-extended';

type Layout = 'default' | 'compact';

type StickyMode = 'pullToShow' | 'alwaysOnTop' | false;

@Component({
    components: {
        SearchScope,
        Indicator,
        NavLinks,
        Departments,
        LogoSmallSvg,
        Heart20Svg,
        Cart20Svg,
        Person20Svg,
        AccountMenu,
        AppLink,
        Search20Svg,
        Cross20Svg,
        Search,
        Notification
    }
})
export default class NavPanel extends Vue {
    @Prop({ type: String, default: () => 'default' }) readonly layout!: Layout;
    @Prop({ type: [String, Boolean], default: () => false })
    readonly stickyMode!: StickyMode;

    @State((state: RootState) => state.wishlist) wishlist!: WishlistState;
    @State((state: RootState) => state.cart) cart!: CartState;
    @State((state: RootState) => state.requestNotifier) requestNotifier!: any;
    @State((state: RootState) => state.offerNotifier) offerNotifier!: any;

    @Ref() readonly departments: Departments | undefined;
    @Ref() readonly nav: NavLinks | undefined;
    @Ref() readonly searchIndicator: Indicator | undefined;
    @Ref() readonly accountIndicator: Indicator | undefined;
    @Ref() readonly search: Search | undefined;
    @Getter('auth/loggedIn') isLoggedIn!: boolean;

    sticky: Sticky | null = null;
    homePage: boolean = false;
    notifyCount = 0;
    
    @Socket('notify')
    notify(data: any) {
        this.notifyCount = 0;
        if (
            data.requestNotifyCount !== undefined &&
            data.requestNotifyCount !== null
        ) {
            this.notifyCount =
                this.notifyCount + parseInt(data.requestNotifyCount);
        }
        if (
            data.offerNotifyCount !== undefined &&
            data.offerNotifyCount !== null
        ) {
            this.notifyCount =
                this.notifyCount + parseInt(data.offerNotifyCount);
        }
        if (
            data.purchaseNotifyCount !== undefined &&
            data.purchaseNotifyCount !== null
        ) {
            this.notifyCount =
                this.notifyCount + parseInt(data.purchaseNotifyCount);
        }
        if (
            data.shopNotifyCount !== undefined &&
            data.shopNotifyCount !== null
        ) {
            this.notifyCount =
                this.notifyCount + parseInt(data.shopNotifyCount);
        }
    }

    mounted() {

        const element = this.$el as HTMLElement;

        this.sticky = new Sticky(element, {
            stuck: 'nav-panel--stuck',
            show: 'nav-panel--show'
        });

        if (this.stickyMode) {
            this.sticky.mode = this.stickyMode;
        }

        this.sticky.on('stuck', () => {
            if (this.departments) {
                this.departments.setStickyState(true);
            }
        });
        this.sticky.on('fix', () => {
            if (this.departments) {
                this.departments.setStickyState(false);
            }
        });
        this.sticky.on('hide', () => {
            if (this.departments) {
                this.departments.closeMenu(true);
            }
            if (this.nav) {
                this.nav.closeSubmenu();
            }
            if (this.searchIndicator) {
                this.searchIndicator.close(true);
            }
            if (this.accountIndicator) {
                this.accountIndicator.close(true);
            }
        });

        this.sticky.start();
        this.sticky.calc(
            element,
            departments.element ? [departments.element] : []
        );
    }

    beforeDestroy() {
        if (this.sticky) {
            this.sticky.destroy();
        }
    }

    onSetDepartmentsArea(area: Element) {
        const element = this.$el as Element;

        if (this.sticky) {
            this.sticky.calc(element, area ? [area] : []);
        }
    }
}
