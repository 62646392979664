




























import { Vue, Component, Prop } from 'vue-property-decorator'
import { ILink } from '~/interfaces/menus/link'
import AppLink from '~/components/shared/app-link.vue'
import ArrowRoundedRight6x9Svg from '~/svg/arrow-rounded-right-6x9.svg'

@Component({
    components: { AppLink, ArrowRoundedRight6x9Svg }
})
export default class PageHeader extends Vue {
    @Prop({ type: String, default: () => '' }) readonly title!: string
    @Prop({ type: Array, default: () => [] }) readonly breadcrumb!: ILink[]
}

