import { GetterTree, MutationTree } from 'vuex'
import dataLanguages, { defaultLocale } from '~/data/languages'

export interface LocaleState {
    current: string;
}

function getDefaultState (): LocaleState {
    return {
        current: defaultLocale
    }
}

export const state = getDefaultState

export const getters: GetterTree<LocaleState, {}> = {
}

export const mutations: MutationTree<LocaleState> = {
}
