































































































































































import { Vue, Component } from 'vue-property-decorator';
import PageHeader from '~/components/shared/page-header.vue';
import Check9x7Svg from '~/svg/check-9x7.svg';
import { LoginUser } from '~/types/user';
import AppLink from '~/components/shared/app-link.vue';
import { Getter } from 'vuex-class';

@Component({
    components: { PageHeader, Check9x7Svg, AppLink },
    head: { title: 'Log In' }
})
export default class Page extends Vue {
    loginUser: any = {} as LoginUser;
    message: string = '';
    passwordType: string = 'password';
    loginStatus: string = 'initial';

    @Getter('auth/loggedIn') isLoggedIn!: boolean;

    async beforeMount() {
        if (this.isLoggedIn) {
            await this.$router.push(this.$url.home());
        }
    }
    async onSubmit() {
        this.loginStatus = 'loading';
        await this.$store.dispatch('auth/login', this.loginUser).then(
            async response => {
                this.loginUser = {};
                this.loginStatus = 'success';
                await this.$router.push(this.$url.home());
            },
            error => {
                this.loginStatus = 'error';
                this.message = error;
            }
        );
    }
}
