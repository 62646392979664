export default {
    name: 'Spark',
    fullName: 'Spark Dijital & Kreatif',
    url: '#',
    author: {
        name: 'Spark Digital & Creative',
        profile_url: 'https://www.sparkdijital.com'
    },
    contacts: {
        name: 'FIND-MYPARTS Yedek Parça Sanayi ve Ticaret',
        address: 'Ilkbahar mah. Güneypark Küme Evleri No.26/39 Çankaya 06550 Ankara TURKIYE',
        email: 'info@find-myparts.com',
        phone: '+905337281438'
    },
    social: {
        instagram: 'https://www.instagram.com/findmyparts',
        linkedin: 'https://www.linkedin.com/in/find-myparts',
        pinterest: 'https://www.pinterest.com/findmypartsonline',
    }
}
