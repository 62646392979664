

































import { Vue, Component } from 'vue-property-decorator'
import theme from '~/data/theme'

@Component
export default class FooterContacts extends Vue {
    theme = theme
}

