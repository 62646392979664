




























































import { Vue, Component } from 'vue-property-decorator';
import AppLink from '~/components/shared/app-link.vue';
import { Getter } from 'vuex-class';
import { MAIN_CONSTANTS } from '~/config/constants';
import { Socket } from 'vue-socket.io-extended';
import BlockLoader from '~/components/blocks/block-loader.vue';

@Component({
    components: { AppLink, BlockLoader }
})
export default class Notification extends Vue {
    message: string = '';
    notifyType: string = '';
    notificationStatus: string = 'initial';
    notificationList: any = [];
    notifyCount = 0;

    @Getter('auth/loggedIn') isLoggedIn!: boolean;
    @Getter('auth/currentUser') user!: any;

    @Socket('notify')
    notify(data: any) {
        this.getUserNotifications();
    }

    async onNotifyClick(notifyType: string) {
        this.notificationStatus = 'initial';
        await this.$store.dispatch('notification/' + notifyType).then(
            response => {
                if (notifyType == 'request') {
                    this.$router.push(this.$url.myRequests());
                } else if (notifyType == 'offer') {
                    this.$router.push(this.$url.myOffers());
                }
            },
            error => {
                this.notificationStatus = 'error';
                this.message = error;
            }
        );
    }

    async clear() {
        await this.$userApi.clearNotifications(
            MAIN_CONSTANTS.NOTIFICATION_TYPE.ALL
        );
    }

    async getUserNotifications() {
        this.notificationStatus = 'initial';
        await this.$userApi.getNotifications().then(
            response => {
                if (response.length > 0) {
                    this.notificationStatus = 'success';
                    this.notificationList = response;
                }
            },
            error => {
                this.message = error.toString();
                this.notificationStatus = 'error';
            }
        );
    }

    notifyRoute(type: number) {
        let url = '';
        if (type === MAIN_CONSTANTS.NOTIFICATION_TYPE.REQUEST) {
            url = this.$url.myRequests();
        } else if (type === MAIN_CONSTANTS.NOTIFICATION_TYPE.OFFER) {
            url = this.$url.myOffers();
        } else if (type === MAIN_CONSTANTS.NOTIFICATION_TYPE.SHOP) {
            url = this.$url.myShop();
        } else if (type === MAIN_CONSTANTS.NOTIFICATION_TYPE.PURCHASE) {
            url = this.$url.myPurchases();
        }
        return url;
    }
}
