var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        'product-card',
        'product-card--hidden-actions',
        'product-card--layout--list'
    ]},[_c('div',{staticClass:"product-card__badges-list"},[(_vm.offer.statusId == 2)?_c('div',{staticClass:"product-card__badge badge-success"},[_vm._v("\n            "+_vm._s(_vm.offer.status.name)+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.offer.statusId == 5)?_c('div',{staticClass:"product-card__badge badge-danger"},[_vm._v("\n            "+_vm._s(_vm.offer.status.name)+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.offer.statusId == 1)?_c('div',{staticClass:"product-card__badge badge-primary text-white"},[_vm._v("\n            "+_vm._s(_vm.offer.status.name)+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.offer.statusId == 6)?_c('div',{staticClass:"product-card__badge badge-secondary text-white"},[_vm._v("\n            "+_vm._s(_vm.offer.status.name)+"\n        ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"badge badge-warning d-flex align-items-center"},[_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.offer.createdAt)))])])]),_vm._v(" "),_c('div',{staticClass:"product-card__image product-image"},[_c('div',{staticClass:"product-image__body",staticStyle:{"padding-bottom":"35%"}}),_vm._v(" "),_c('div',{staticClass:"product-card__availability mb-1"},[_vm._v("\n            Product ID:\n            "),_c('span',{staticStyle:{"color":"#a37f12"}},[_vm._v(_vm._s(_vm.offer.id))])]),_vm._v(" "),_c('div',{staticClass:"product-card__availability mb-1"},[_vm._v("\n            Offer ID:\n            "),_c('span',{staticStyle:{"color":"#a37f12"}},[_vm._v(_vm._s(_vm.offer.id))])]),_vm._v(" "),_c('div',{staticClass:"product-card__availability"},[_vm._v("\n            Location:\n            "),_c('br'),_vm._v(" "),_c('span',{staticStyle:{"color":"#a37f12"}},[_vm._v("\n                "+_vm._s(_vm.offer.shippingAddress &&
                        _vm.offer.shippingAddress.country.name.toUpperCase())+"\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"product-card__info p-4"},[_c('div',{staticClass:"product-card__name"},[_c('h3',[_vm._v(_vm._s(_vm.offer.description))])]),_vm._v(" "),_c('div',{staticClass:"d-flex justify-content-between col-md-10 col-lg-10 p-0"},[_c('div',[_c('div',{staticClass:"product-card__title"},[_vm._v("Conditions")]),_vm._v(" "),(_vm.offer.conditions)?_c('ul',{staticClass:"product-card__features-list"},_vm._l((_vm.offer.conditions),function(condition,index){return _c('li',{key:index},[_vm._v("\n                        "+_vm._s(condition.name)+"\n                    ")])}),0):_vm._e()]),_vm._v(" "),_c('div',[_c('div',{staticClass:"product-card__title"},[_vm._v("Manufacturers")]),_vm._v(" "),(_vm.offer.manufacturers)?_c('ul',{staticClass:"product-card__features-list"},_vm._l((_vm.offer.manufacturers),function(manufacturer,index){return _c('li',{key:index},[_vm._v("\n                        "+_vm._s(manufacturer.name)+"\n                    ")])}),0):_vm._e()])])]),_vm._v(" "),_c('div',{staticClass:"product-card__actions"},[_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"badge badge-success mt-1 px-2"},[_c('span',{staticStyle:{"font-size":"1.7em"}},[_vm._v("$ "+_vm._s(_vm.offer.amount))])])]),_vm._v(" "),_c('div',{staticClass:"product-card__buttons mt-3"},[(_vm.isLoggedIn && _vm.type !== 'offer')?_c('button',{class:[
                    'btn',
                    { 'btn-loading': _vm.isLoading },
                    { 'btn-primary': _vm.offer.statusId == 1 },
                    { 'btn-success': _vm.offer.statusId == 2 }
                ],attrs:{"disabled":_vm.offer.statusId == 2 || _vm.partStatus == 2,"type":"button"},on:{"click":function($event){return _vm.acceptOffer(_vm.offer.id)}}},[_vm._v("\n                "+_vm._s(_vm.offer.statusId == 1 ? 'Accept Offer' : 'Accepted')+"\n            ")]):_vm._e(),_vm._v(" "),(
                    _vm.isLoggedIn &&
                        _vm.type == 'offer' &&
                        _vm.offer.statusId == 2 &&
                        _vm.isSupplier
                )?_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.contact-info-box",modifiers:{"contact-info-box":true}}],class:['btn', { 'btn-success': _vm.offer.statusId == 2 }],attrs:{"type":"button"},on:{"click":function($event){return _vm.openContactBox()}}},[_vm._v("\n                Contact with Requester\n            ")]):_vm._e(),_vm._v(" "),(
                    _vm.isLoggedIn &&
                        _vm.type == 'offer' &&
                        _vm.offer.statusId == 2 &&
                        !_vm.isSupplier
                )?_c('b-button',{class:['btn', { 'btn-success': _vm.offer.statusId == 2 }],attrs:{"type":"button","to":_vm.$url.sellWithUs()}},[_vm._v("\n                Contact with Requester\n            ")]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }