import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import {
    required,
    max,
    min,
    email,
    alpha,
    alpha_spaces,
    confirmed
} from 'vee-validate/dist/rules';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

extend('required', {
    ...required,
    message: 'This field is required !'
});

extend('email', {
    ...email,
    message: 'Valid email is required !'
});

extend('password', {
    ...email,
    message: 'Valid email is required !'
});

extend('alpha', {
    ...alpha,
    message: 'This field may only contain alphabetic characters.'
});

extend('alpha_spaces', {
    ...alpha_spaces,
    message:
        'This field may only contain alphabetic characters as well as spaces.'
});

extend('confirmed', {
    ...confirmed,
    message: 'Passwords do not match ! Please check your password.'
});

extend('min', {
    ...min,
    params: ['min'],
    message: 'The {_field_} must be at least {min} characters !'
});

extend('max', {
    ...max,
    message: 'The {_field_} field has reached max characters !',
});

extend('minmax', {
    validate(value, { min, max} : any) {
        return value.length >= min && value.length <= max;
    },
    params: ['min', 'max'],
    message:
        'The {_field_} field must have at least {min} characters and {max} characters at most.'
});

extend('image', {
    validate(value) {
        return value;
    },
    message:
        'Image validation error !'
});
