
























import { Vue, Component, Prop } from 'vue-property-decorator'
import { INestedLink } from '~/interfaces/menus/link'
import AppLink from '~/components/shared/app-link.vue'

@Component({
    name: 'MegamenuLinks',
    components: { AppLink }
})
export default class MegamenuLinks extends Vue {
    @Prop({ type: Array, default: () => [] }) readonly links!: INestedLink[]
    @Prop({ type: Number, default: () => 0 }) readonly level!: number
}

