/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedLocalSymbols

import { Context, Plugin } from '@nuxt/types';
import { IUserAddress, INewUserAddress } from '~/interfaces/address';
import { RegisterUser, LoginUser } from '~/types/user';
import authHeader from '~/services/auth-header';
import axios from '../config/http-common';

function make(context: Context) {
    return {
        /**
         * Get a user with uid.
         */
        getUser: async (): Promise<any> => {
            return await axios.get('user', { headers: authHeader() });
        },
        /**
         * Update a user.
         */
        updateUser: (user: any): Promise<any> => {
            return axios.put('user', user, { headers: authHeader() }).then(
                response => {
                    return response.data.message;
                },
                error => {
                    return error.response.data.message;
                }
            );
        },
        /**
         * Login with credentials.
         */
        Login: async (user: LoginUser): Promise<any> => {
            return await axios.post('auth/login', user);
        },
        /**
         * Register a new user.
         */
        Register: async (user: RegisterUser): Promise<any> => {
            return await axios.post('auth/register', user);
        },
        /**
         * Returns addresses for a user.
         */
        getUserAddresses: async (): Promise<IUserAddress[]> => {
            const response = await axios.get('user/address', { headers: authHeader() });
            return response.data;
        },
        /**
         * Get an address with addressId.
         */
        getAddress: async (addressId: string): Promise<any> => {
            try {
                const response = await axios.get(
                    'user/address/' + addressId, { headers: authHeader() }
                );
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Create a new address for a user.
         */
        createAddress: async (
            address: INewUserAddress
        ): Promise<any> => {
            try {
                const response = await axios.post(
                    'user/address',
                    address, { headers: authHeader() }
                );
                return response;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Update an address.
         */
        updateAddress: async(
            addressId: string,
            address: any
        ): Promise<any> => {
            return axios
                .put('user/address/' + addressId, address, { headers: authHeader() })
                .then(
                    response => {
                        return response.data.message;
                    },
                    error => {
                        return error.response.data.message;
                    }
                );
        },
        /**
         * Remove the address and associated rows from database.
         */
        removeAddress: async (addressId: any): Promise<any> => {
            try {
                const response = await axios.delete(
                    'user/address/' + addressId, { headers: authHeader() }
                );
                return response;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Create a new part.
         */
        createPart: async (part: any, type: string, images?: any): Promise<any> => {
            var formData = new FormData();

            var params = {
                title: part.title,
                price: part.price,
                categoryId: part.categoryId,
                addressId: part.addressId,
                model: part?.brandName ? part?.brandName : null,
                no: part?.no ? part?.no : null,
                description: part.description,
                qty: part.qty,
                conditions: part.conditions,
                manufacturers: part.manufacturers,
                brandName: part?.brandName ? part?.brandName : null,
                brandId: part?.brandName ? null : part?.brandId,
                type: type
            };

            if(images){
                for (let j = 0; j < images.length; j++) {
                    formData.append('images', images[j]);
                  }
            }

            formData.append('part', JSON.stringify(params))

            try {
                const response = await axios.post(
                    '/part/user',
                    formData, { headers: authHeader() }
                );
                return response;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Make an offer for a part.
         */
        makeOffer: async (offer: string, partId: any): Promise<any> => {
            var params = offer;
            try {
                const response = await axios.post(
                    '/offer/create/' + partId,
                    params, { headers: authHeader() }
                );
                return response;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Get user parts.
         */
        getUserParts: async (): Promise<any> => {
            try {
                const response = await axios.get(
                    '/part/user', { headers: authHeader() }
                );
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Get user notifications.
         */
        getNotifications: async (): Promise<any> => {
            try {
                const response = await axios.get(
                    '/notification', { headers: authHeader() }
                );
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Clear user notifications.
         */
        clearNotifications: async (type: number): Promise<any> => {
            try {
                const response = await axios.get(
                    '/notification/clear/' + type, { headers: authHeader() }
                );
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Save payment details to the database to retrieve iframe_token from PAYTR
         */
        savePaymentDetails: async (paymentData: any): Promise<any> => {
            var params = paymentData;
            try {
                const response = await axios.post(
                    '/payment/save',
                    params, { headers: authHeader() }
                );
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
        * Get subscription list.
        */
        getSubscriptionList: async (): Promise<any> => {
            try {
                const response = await axios.get(
                    'user/admin/getSubscriptionList', { headers: authHeader() }
                );
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
        * Get user subscription list.
        */
        getUserSubscriptions: async (uid: string, type: string): Promise<any> => {
            try {
                const response = await axios.get(
                    'user/' + uid + '/subscription/' + type, { headers: authHeader() }
                );
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
        * Check user subscription status.
        */
         checkUserSubscription: async (type: string): Promise<any> => {
            try {
                const response = await axios.get(
                    'user/checkUserSubscription/' + type, { headers: authHeader() }
                );
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Changes password of a user.
         */
        changePassword: (data: any): Promise<any> => {
            return axios.put('user/change-password', data, { headers: authHeader() }).then(
                response => {
                    return {status: response.status, message: response.data.message};;
                },
                error => {
                    return {status: error.response.status, message: error.response.data.message};
                }
            );
        },
        /**
         * Save payment details to the database to retrieve iframe_token from PAYTR
         */
        paymentResult: async (paymentData: any): Promise<any> => {
            var params = paymentData;
            try {
                const response = await axios.post(
                    '/payment/result',
                    params, { headers: authHeader() }
                );
                return response.data;
            } catch (error: any) {
                throw new Error(error);
            }
        },
        /**
         * Return price of plans in TL.
         */
        getPlans: async (
            ): Promise<any> => {
                try {
                    const response = await axios.get('/payment/plans');
                    return response.data;
                } catch (error: any) {
                    throw new Error(error);
                }
            },
    };
}

declare module 'vue/types/vue' {
    interface Vue {
        $userApi: ReturnType<typeof make> & Context;
    }
}

declare module '@nuxt/types' {
    interface Context {
        $userApi: ReturnType<typeof make> & Context;
    }
}

declare module 'vuex/types/index' {
    interface Page<S> {
        $userApi: ReturnType<typeof make> & Context;
    }
}

declare module 'vuex/types/index' {
    interface Store<S> {
        $userApi: ReturnType<typeof make> & Context;
    }
}

const plugin: Plugin = (context, inject) => {
    inject('userApi', make(context));
};

export type userApi = ReturnType<typeof make>;

export default plugin;
