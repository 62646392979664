import { render, staticRenderFns } from "./megamenu-links.vue?vue&type=template&id=5079763d&"
import script from "./megamenu-links.vue?vue&type=script&lang=ts&"
export * from "./megamenu-links.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports