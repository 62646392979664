










































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { IProduct } from '~/interfaces/product';
import Rating from '~/components/shared/rating.vue';
import ProductGallery from '~/components/shared/product-gallery.vue';
import AppLink from '~/components/shared/app-link.vue';
import AsyncAction from '~/components/shared/async-action.vue';
import InputNumber from '~/components/shared/input-number.vue';
import Wishlist16Svg from '~/svg/wishlist-16.svg';
import { ValidationProvider } from 'vee-validate';
import { IUserAddress } from '~/interfaces/address';
import { Getter, Action } from 'vuex-class';
import StatusMessage from '~/components/shared/status-message.vue';

export type ProductLayout = 'standard' | 'sidebar' | 'columnar' | 'quickview';

@Component({
    components: {
        Rating,
        AppLink,
        ProductGallery,
        AsyncAction,
        Wishlist16Svg,
        InputNumber,
        StatusMessage
    }
})
export default class Product extends Vue {
    @Getter('auth/currentUser') user!: any;
    @Prop({ type: String, required: true }) readonly layout!: ProductLayout;
    @Prop({ type: Object, required: true }) readonly product!: IProduct;
    @Action('shop/resetFilters') resetFilters!: () => void;

    offer: any = {
        qty: 1
    };
    qty: number | string = 1;
    addresses: IUserAddress[] = [];
    isLoading: boolean = false;
    status: string = 'initial';
    message: string = '';
    imageList: any = [];

    condition: any = {
        new: { status: false, id: 1 },
        reconditioned: { status: false, id: 2 },
        used: { status: false, id: 3 }
    };
    manufacturer: any = {
        original: { status: false, id: 1 },
        oem: { status: false, id: 2 },
        aftermarket: { status: false, id: 3 }
    };

    beforeMount() {
        this.offer.addressId = 0;

        this.getAddresses();
        var imageUrls = JSON.parse(this.product.imageUrls);
        if (this.product.imagesCount > 0) {
            for (let i = 0; i < this.product.imagesCount; i++) {
                this.imageList.push(
                    '/public/images/' +
                        this.product.id +
                        '/' +
                        i +
                        '.' +
                        imageUrls[i]
                );
            }
        } else {
            this.imageList.push('/public/images/no-img.jpg');
        }
    }

    async getAddresses() {
        await this.$userApi.getUserAddresses().then(addresses => {
            this.addresses = addresses;
        });
    }

    handleForm() {
        if (this.offer.addressId == 0) {
            return 'Please select address.';
        }
        if (this.prepareOfferAttrs(this.condition, 'conditions') == 0) {
            return 'Please select condition(s).';
        }
        if (this.prepareOfferAttrs(this.manufacturer, 'manufacturers') == 0) {
            return 'Please select manufacturer(s).';
        }

        return 'success';
    }

    prepareOfferAttrs(attr: any, property: string) {
        var status = 0;
        var attrs = Object.values(attr);
        var tempArr: number[] = [];
        for (let i = 0; i < attrs.length; i++) {
            var elem: any = attrs[i];
            if (elem.status) {
                tempArr.push(elem.id);
                status++;
            }
        }
        if (property == 'conditions') {
            this.offer.conditions = [...tempArr];
        } else if (property == 'manufacturers') {
            this.offer.manufacturers = [...tempArr];
        }

        return status;
    }

    confirmBuy(product: any) {
        this.$bvModal
            .msgBoxConfirm(
                'Are you sure you want to buy this item? Seller will be notified after this action.',
                {
                    title: 'Please Confirm',
                    size: 'md',
                    buttonSize: 'md',
                    okVariant: 'warning',
                    okTitle: 'Confirm',
                    cancelTitle: 'No',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                    html: true
                }
            )
            .then(value => {
                if (value) {
                    this.$store.commit('quickview/close');
                    this.$store
                        .dispatch('cart/buy', {product})
                        .then(buyResponse => {
                            this.resetFilters();
                        });
                } else {
                    this.isLoading = false;
                }
            })
            .catch(err => {
                // An error occurred
                console.log('err:', err);
            });
    }
}
