



























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { IFilterValues, IListOptions } from '~/interfaces/list';
import Pagination from '~/components/shared/pagination.vue';
import ProductCard from '~/components/shared/product-card.vue';
import Filters16Svg from '~/svg/filters-16.svg';
import AppLink from '~/components/shared/app-link.vue';
import OfferCard from '~/components/shared/offer-card.vue';

export type ProductsViewLayout = 'grid' | 'grid-with-features' | 'list';
export type ProductsViewGrid = 'grid-3-sidebar' | 'grid-4-full' | 'grid-5-full';
export type ProductsViewOffcanvas = 'always' | 'mobile';

@Component({
    components: { Pagination, ProductCard, Filters16Svg, AppLink, OfferCard }
})
export default class ProductsView extends Vue {
    @Prop({ type: Number, default: () => null })
    readonly partId!: any;
    offerList: any = { items: [] };
    options: any = {};
    page: number = 1;

    async beforeMount() {
        this.options = {
            pId: this.partId
        };
        await this.getPartOffers();
    }

    async getPartOffers() {
        await this.$shopApi
            .getPartOffers(this.partId, this.options)
            .then(response => {
                this.offerList = response;
            });
    }

    async handlePageChange(page: number) {
        this.options.page = page;
        await this.getPartOffers();
    }

    async handleLimitChange(event: InputEvent) {
        if (!(event.target instanceof HTMLSelectElement)) {
            return;
        }

        this.options.limit = parseFloat(event.target.value);
        await this.getPartOffers();
    }
}
