
























































































import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { RootState } from '~/store';
import { WishlistState } from '~/store/wishlist';
import { CartState } from '~/store/cart';
import Sticky from '~/services/sticky';
import AppLink from '~/components/shared/app-link.vue';
import Notification from '~/components/header/notification.vue';
import Search from '~/components/header/search.vue';
import Indicator from '~/components/header/indicator.vue';
import LogoSmallSvg from '~/svg/logo-small.svg';
import Menu18x14Svg from '~/svg/menu-18x14.svg';
import Person20Svg from '~/svg/person-20.svg';
import Cart20Svg from '~/svg/cart-20.svg';
import Heart20Svg from '~/svg/heart-20.svg';
import { Getter } from 'vuex-class';
import { Socket } from 'vue-socket.io-extended';

type StickyMode = 'pullToShow' | 'alwaysOnTop' | false;

@Component({
    components: {
        Indicator,
        Search,
        AppLink,
        Menu18x14Svg,
        LogoSmallSvg,
        Cart20Svg,
        Person20Svg,
        Heart20Svg,
        Notification
    }
})
export default class MobileHeader extends Vue {
    @Prop({ type: [String, Boolean], default: () => false })
    readonly stickyMode!: StickyMode;

    @State((state: RootState) => state.wishlist) wishlist!: WishlistState;
    @State((state: RootState) => state.cart) cart!: CartState;

    @Ref() readonly search!: Search;
    @Ref() readonly searchButton!: Indicator;

    @Getter('auth/loggedIn') isLoggedIn!: boolean;
    notifyCount = 0;

    @Socket('notify')
    notify(data: any) {
        this.notifyCount = 0;
        if (
            data.requestNotifyCount !== undefined &&
            data.requestNotifyCount !== null
        ) {
            this.notifyCount =
                this.notifyCount + parseInt(data.requestNotifyCount);
        }
        if (
            data.offerNotifyCount !== undefined &&
            data.offerNotifyCount !== null
        ) {
            this.notifyCount =
                this.notifyCount + parseInt(data.offerNotifyCount);
        }
        if (
            data.purchaseNotifyCount !== undefined &&
            data.purchaseNotifyCount !== null
        ) {
            this.notifyCount =
                this.notifyCount + parseInt(data.purchaseNotifyCount);
        }
        if (
            data.shopNotifyCount !== undefined &&
            data.shopNotifyCount !== null
        ) {
            this.notifyCount =
                this.notifyCount + parseInt(data.shopNotifyCount);
        }
    }

    sticky: Sticky = null!;

    searchIsOpen: boolean = false;

    teardown: Array<() => void> = [];

    mounted() {
        document.addEventListener('click', this.onGlobalClick);

        this.teardown.push(() =>
            document.removeEventListener('click', this.onGlobalClick)
        );

        const element = this.$el as HTMLElement;

        this.sticky = new Sticky(element, {
            stuck: 'mobile-header--stuck',
            show: 'mobile-header--show'
        });

        this.sticky.start();
        this.sticky.calc(element);
    }

    beforeDestroy() {
        this.sticky &&  this.sticky.destroy();
        this.teardown.forEach(x => x());
    }

    onSearchClick() {
        this.searchIsOpen = true;
        setTimeout(() => {
            this.search.focus();
        }, 0);
    }

    onSearchClose() {
        this.searchIsOpen = false;
    }

    onGlobalClick(event: MouseEvent) {
        // const search = this.search.$el;
        // const searchButton = this.searchButton?.$el;
        // // Close search if click performed outside of component.
        // if (
        //     event.target instanceof HTMLElement &&
        //     !(
        //         search.contains(event.target) ||
        //         searchButton.contains(event.target)
        //     )
        // ) {
        //     this.searchIsOpen = false;
        // }
    }
}
