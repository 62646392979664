var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{class:("mobile-links mobile-links--level--" + _vm.level)},[_vm._l((_vm.links),function(link,index){return [_c('Collapse',{key:index,attrs:{"item-open-class":"mobile-links__item--open"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var itemClasses = ref.itemClasses;
var contentClasses = ref.contentClasses;
var toggle = ref.toggle;
return [(link.type === 'link' || link.type === 'button')?_c('li',{class:[
                    'mobile-links__item',
                    itemClasses
                ]},[_c('div',{staticClass:"mobile-links__item-title"},[(link.type === 'link')?_c('AppLink',{staticClass:"mobile-links__item-link",attrs:{"to":link.url},nativeOn:{"click":function($event){return _vm.onItemClick(link)}}},[(link.icon)?_c('i',{staticClass:"nav-links__item-icon",class:link.icon}):_vm._e(),_vm._v("\n                        "+_vm._s(link.title)+"\n                    ")]):_vm._e(),_vm._v(" "),(link.type === 'button')?_c('button',{staticClass:"mobile-links__item-link",attrs:{"type":"button"},on:{"click":function($event){toggle; _vm.onItemClick(link)}}},[(link.icon)?_c('i',{staticClass:"nav-links__item-icon",class:link.icon}):_vm._e(),_vm._v("\n                        "+_vm._s(link.title)+"\n                    ")]):_vm._e(),_vm._v(" "),(link.children && link.children.length > 0)?_c('button',{staticClass:"mobile-links__item-toggle",attrs:{"type":"button"},on:{"click":toggle}},[_c('ArrowRoundedDown12x7Svg',{staticClass:"mobile-links__item-arrow"})],1):_vm._e()],1),_vm._v(" "),(link.children && link.children.length > 0)?_c('div',{class:['mobile-links__item-sub-links', contentClasses]},[_c('MobileLinks',{attrs:{"links":link.children,"level":_vm.level + 1},on:{"itemClick":_vm.onItemClick}})],1):_vm._e()]):_vm._e()]}}],null,true)}),_vm._v(" "),(link.type === 'divider')?_c('li',{key:index,staticClass:"mobile-links__divider"}):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }