import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _42ace8d0 = () => interopDefault(import('../src/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _62a8bd75 = () => interopDefault(import('../src/pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _56da829c = () => interopDefault(import('../src/pages/request/index.vue' /* webpackChunkName: "pages/request/index" */))
const _373d606e = () => interopDefault(import('../src/pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _2d843f23 = () => interopDefault(import('../src/pages/account/addresses/index.vue' /* webpackChunkName: "pages/account/addresses/index" */))
const _618247bf = () => interopDefault(import('../src/pages/account/admin-page.vue' /* webpackChunkName: "pages/account/admin-page" */))
const _1ccd7292 = () => interopDefault(import('../src/pages/account/cart.vue' /* webpackChunkName: "pages/account/cart" */))
const _a52752dc = () => interopDefault(import('../src/pages/account/dashboard.vue' /* webpackChunkName: "pages/account/dashboard" */))
const _b8458656 = () => interopDefault(import('../src/pages/account/favorites.vue' /* webpackChunkName: "pages/account/favorites" */))
const _0c548be7 = () => interopDefault(import('../src/pages/account/login.vue' /* webpackChunkName: "pages/account/login" */))
const _3ebbbed6 = () => interopDefault(import('../src/pages/account/my-offers.vue' /* webpackChunkName: "pages/account/my-offers" */))
const _4ae69cba = () => interopDefault(import('../src/pages/account/my-purchases.vue' /* webpackChunkName: "pages/account/my-purchases" */))
const _15bfbe7a = () => interopDefault(import('../src/pages/account/my-requests.vue' /* webpackChunkName: "pages/account/my-requests" */))
const _117ffe75 = () => interopDefault(import('../src/pages/account/my-shop.vue' /* webpackChunkName: "pages/account/my-shop" */))
const _28ac7f8d = () => interopDefault(import('../src/pages/account/password.vue' /* webpackChunkName: "pages/account/password" */))
const _448e04a7 = () => interopDefault(import('../src/pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _bdd4a0d6 = () => interopDefault(import('../src/pages/account/register.vue' /* webpackChunkName: "pages/account/register" */))
const _455bc830 = () => interopDefault(import('../src/pages/blog/category-list.vue' /* webpackChunkName: "pages/blog/category-list" */))
const _1a233be9 = () => interopDefault(import('../src/pages/blog/create.vue' /* webpackChunkName: "pages/blog/create" */))
const _02918534 = () => interopDefault(import('../src/pages/blog/success.vue' /* webpackChunkName: "pages/blog/success" */))
const _38e67b5b = () => interopDefault(import('../src/pages/parts/create.vue' /* webpackChunkName: "pages/parts/create" */))
const _2a5d93df = () => interopDefault(import('../src/pages/parts/create-as-guest.vue' /* webpackChunkName: "pages/parts/create-as-guest" */))
const _8f482798 = () => interopDefault(import('../src/pages/parts/success.vue' /* webpackChunkName: "pages/parts/success" */))
const _61804262 = () => interopDefault(import('../src/pages/shop/checkout/index.vue' /* webpackChunkName: "pages/shop/checkout/index" */))
const _4674b8f6 = () => interopDefault(import('../src/pages/site/browse-parts.vue' /* webpackChunkName: "pages/site/browse-parts" */))
const _5723aa13 = () => interopDefault(import('../src/pages/site/contact-us.vue' /* webpackChunkName: "pages/site/contact-us" */))
const _00e6726f = () => interopDefault(import('../src/pages/site/how-to-sell.vue' /* webpackChunkName: "pages/site/how-to-sell" */))
const _32c16f32 = () => interopDefault(import('../src/pages/site/our-writers.vue' /* webpackChunkName: "pages/site/our-writers" */))
const _53f3ca1d = () => interopDefault(import('../src/pages/site/register-bonus.vue' /* webpackChunkName: "pages/site/register-bonus" */))
const _aa90200a = () => interopDefault(import('../src/pages/account/addresses/create.vue' /* webpackChunkName: "pages/account/addresses/create" */))
const _b47d89da = () => interopDefault(import('../src/pages/shop/checkout/success.vue' /* webpackChunkName: "pages/shop/checkout/success" */))
const _02690c38 = () => interopDefault(import('../src/pages/shop/parts/create.vue' /* webpackChunkName: "pages/shop/parts/create" */))
const _a1d2826a = () => interopDefault(import('../src/pages/shop/parts/success.vue' /* webpackChunkName: "pages/shop/parts/success" */))
const _695f6540 = () => interopDefault(import('../src/pages/site/subscription/callbackurl.vue' /* webpackChunkName: "pages/site/subscription/callbackurl" */))
const _0d2b5c82 = () => interopDefault(import('../src/pages/site/subscription/payment-fail.vue' /* webpackChunkName: "pages/site/subscription/payment-fail" */))
const _cf0f2d1c = () => interopDefault(import('../src/pages/site/subscription/payment-success.vue' /* webpackChunkName: "pages/site/subscription/payment-success" */))
const _5a4dae4b = () => interopDefault(import('../src/pages/account/addresses/_id.vue' /* webpackChunkName: "pages/account/addresses/_id" */))
const _686dfea6 = () => interopDefault(import('../src/pages/blog/category/_slug.vue' /* webpackChunkName: "pages/blog/category/_slug" */))
const _60f21c2d = () => interopDefault(import('../src/pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _5a47c52c = () => interopDefault(import('../src/pages/request/_slug.vue' /* webpackChunkName: "pages/request/_slug" */))
const _3aaaa2fe = () => interopDefault(import('../src/pages/shop/_slug.vue' /* webpackChunkName: "pages/shop/_slug" */))
const _069af632 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _42ace8d0,
    name: "account"
  }, {
    path: "/blog",
    component: _62a8bd75,
    name: "blog"
  }, {
    path: "/request",
    component: _56da829c,
    name: "request"
  }, {
    path: "/shop",
    component: _373d606e,
    name: "shop"
  }, {
    path: "/account/addresses",
    component: _2d843f23,
    name: "account-addresses"
  }, {
    path: "/account/admin-page",
    component: _618247bf,
    name: "account-admin-page"
  }, {
    path: "/account/cart",
    component: _1ccd7292,
    name: "account-cart"
  }, {
    path: "/account/dashboard",
    component: _a52752dc,
    name: "account-dashboard"
  }, {
    path: "/account/favorites",
    component: _b8458656,
    name: "account-favorites"
  }, {
    path: "/account/login",
    component: _0c548be7,
    name: "account-login"
  }, {
    path: "/account/my-offers",
    component: _3ebbbed6,
    name: "account-my-offers"
  }, {
    path: "/account/my-purchases",
    component: _4ae69cba,
    name: "account-my-purchases"
  }, {
    path: "/account/my-requests",
    component: _15bfbe7a,
    name: "account-my-requests"
  }, {
    path: "/account/my-shop",
    component: _117ffe75,
    name: "account-my-shop"
  }, {
    path: "/account/password",
    component: _28ac7f8d,
    name: "account-password"
  }, {
    path: "/account/profile",
    component: _448e04a7,
    name: "account-profile"
  }, {
    path: "/account/register",
    component: _bdd4a0d6,
    name: "account-register"
  }, {
    path: "/blog/category-list",
    component: _455bc830,
    name: "blog-category-list"
  }, {
    path: "/blog/create",
    component: _1a233be9,
    name: "blog-create"
  }, {
    path: "/blog/success",
    component: _02918534,
    name: "blog-success"
  }, {
    path: "/parts/create",
    component: _38e67b5b,
    name: "parts-create"
  }, {
    path: "/parts/create-as-guest",
    component: _2a5d93df,
    name: "parts-create-as-guest"
  }, {
    path: "/parts/success",
    component: _8f482798,
    name: "parts-success"
  }, {
    path: "/shop/checkout",
    component: _61804262,
    name: "shop-checkout"
  }, {
    path: "/site/browse-parts",
    component: _4674b8f6,
    name: "site-browse-parts"
  }, {
    path: "/site/contact-us",
    component: _5723aa13,
    name: "site-contact-us"
  }, {
    path: "/site/how-to-sell",
    component: _00e6726f,
    name: "site-how-to-sell"
  }, {
    path: "/site/our-writers",
    component: _32c16f32,
    name: "site-our-writers"
  }, {
    path: "/site/register-bonus",
    component: _53f3ca1d,
    name: "site-register-bonus"
  }, {
    path: "/account/addresses/create",
    component: _aa90200a,
    name: "account-addresses-create"
  }, {
    path: "/shop/checkout/success",
    component: _b47d89da,
    name: "shop-checkout-success"
  }, {
    path: "/shop/parts/create",
    component: _02690c38,
    name: "shop-parts-create"
  }, {
    path: "/shop/parts/success",
    component: _a1d2826a,
    name: "shop-parts-success"
  }, {
    path: "/site/subscription/callbackurl",
    component: _695f6540,
    name: "site-subscription-callbackurl"
  }, {
    path: "/site/subscription/payment-fail",
    component: _0d2b5c82,
    name: "site-subscription-payment-fail"
  }, {
    path: "/site/subscription/payment-success",
    component: _cf0f2d1c,
    name: "site-subscription-payment-success"
  }, {
    path: "/account/addresses/:id",
    component: _5a4dae4b,
    name: "account-addresses-id"
  }, {
    path: "/blog/category/:slug?",
    component: _686dfea6,
    name: "blog-category-slug"
  }, {
    path: "/blog/:slug",
    component: _60f21c2d,
    name: "blog-slug"
  }, {
    path: "/request/:slug",
    component: _5a47c52c,
    name: "request-slug"
  }, {
    path: "/shop/:slug",
    component: _3aaaa2fe,
    name: "shop-slug"
  }, {
    path: "/",
    component: _069af632,
    name: "index"
  }, {
    path: "/:lang/account",
    component: _42ace8d0,
    name: "lang-account"
  }, {
    path: "/:lang/blog",
    component: _62a8bd75,
    name: "lang-blog"
  }, {
    path: "/:lang/request",
    component: _56da829c,
    name: "lang-request"
  }, {
    path: "/:lang/shop",
    component: _373d606e,
    name: "lang-shop"
  }, {
    path: "/:lang/account/addresses",
    component: _2d843f23,
    name: "lang-account-addresses"
  }, {
    path: "/:lang/account/admin-page",
    component: _618247bf,
    name: "lang-account-admin-page"
  }, {
    path: "/:lang/account/cart",
    component: _1ccd7292,
    name: "lang-account-cart"
  }, {
    path: "/:lang/account/dashboard",
    component: _a52752dc,
    name: "lang-account-dashboard"
  }, {
    path: "/:lang/account/favorites",
    component: _b8458656,
    name: "lang-account-favorites"
  }, {
    path: "/:lang/account/login",
    component: _0c548be7,
    name: "lang-account-login"
  }, {
    path: "/:lang/account/my-offers",
    component: _3ebbbed6,
    name: "lang-account-my-offers"
  }, {
    path: "/:lang/account/my-purchases",
    component: _4ae69cba,
    name: "lang-account-my-purchases"
  }, {
    path: "/:lang/account/my-requests",
    component: _15bfbe7a,
    name: "lang-account-my-requests"
  }, {
    path: "/:lang/account/my-shop",
    component: _117ffe75,
    name: "lang-account-my-shop"
  }, {
    path: "/:lang/account/password",
    component: _28ac7f8d,
    name: "lang-account-password"
  }, {
    path: "/:lang/account/profile",
    component: _448e04a7,
    name: "lang-account-profile"
  }, {
    path: "/:lang/account/register",
    component: _bdd4a0d6,
    name: "lang-account-register"
  }, {
    path: "/:lang/blog/category-list",
    component: _455bc830,
    name: "lang-blog-category-list"
  }, {
    path: "/:lang/blog/create",
    component: _1a233be9,
    name: "lang-blog-create"
  }, {
    path: "/:lang/blog/success",
    component: _02918534,
    name: "lang-blog-success"
  }, {
    path: "/:lang/parts/create",
    component: _38e67b5b,
    name: "lang-parts-create"
  }, {
    path: "/:lang/parts/create-as-guest",
    component: _2a5d93df,
    name: "lang-parts-create-as-guest"
  }, {
    path: "/:lang/parts/success",
    component: _8f482798,
    name: "lang-parts-success"
  }, {
    path: "/:lang/shop/checkout",
    component: _61804262,
    name: "lang-shop-checkout"
  }, {
    path: "/:lang/site/browse-parts",
    component: _4674b8f6,
    name: "lang-site-browse-parts"
  }, {
    path: "/:lang/site/contact-us",
    component: _5723aa13,
    name: "lang-site-contact-us"
  }, {
    path: "/:lang/site/how-to-sell",
    component: _00e6726f,
    name: "lang-site-how-to-sell"
  }, {
    path: "/:lang/site/our-writers",
    component: _32c16f32,
    name: "lang-site-our-writers"
  }, {
    path: "/:lang/site/register-bonus",
    component: _53f3ca1d,
    name: "lang-site-register-bonus"
  }, {
    path: "/:lang/account/addresses/create",
    component: _aa90200a,
    name: "lang-account-addresses-create"
  }, {
    path: "/:lang/shop/checkout/success",
    component: _b47d89da,
    name: "lang-shop-checkout-success"
  }, {
    path: "/:lang/shop/parts/create",
    component: _02690c38,
    name: "lang-shop-parts-create"
  }, {
    path: "/:lang/shop/parts/success",
    component: _a1d2826a,
    name: "lang-shop-parts-success"
  }, {
    path: "/:lang/site/subscription/callbackurl",
    component: _695f6540,
    name: "lang-site-subscription-callbackurl"
  }, {
    path: "/:lang/site/subscription/payment-fail",
    component: _0d2b5c82,
    name: "lang-site-subscription-payment-fail"
  }, {
    path: "/:lang/site/subscription/payment-success",
    component: _cf0f2d1c,
    name: "lang-site-subscription-payment-success"
  }, {
    path: "/:lang/account/addresses/:id",
    component: _5a4dae4b,
    name: "lang-account-addresses-id"
  }, {
    path: "/:lang/blog/category/:slug?",
    component: _686dfea6,
    name: "lang-blog-category-slug"
  }, {
    path: "/:lang/blog/:slug",
    component: _60f21c2d,
    name: "lang-blog-slug"
  }, {
    path: "/:lang/request/:slug",
    component: _5a47c52c,
    name: "lang-request-slug"
  }, {
    path: "/:lang/shop/:slug",
    component: _3aaaa2fe,
    name: "lang-shop-slug"
  }, {
    path: "/:lang/",
    component: _069af632,
    name: "lang-index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
